<template>
  <div v-if="isAuthenticated" />
</template>

<script>
import { defineComponent } from "@/composition-api";
import debounce from "lodash.debounce";
import useAuthentication from "@/composition/useAuthentication";

export default defineComponent({
  setup() {
    const { isAuthenticated } = useAuthentication();

    return { isAuthenticated };
  },
  computed: {
    deepLink() {
      if (this.$route.query.path) {
        const path = Array.isArray(this.$route.query.path)
          ? this.$route.query.path.find(p => p !== "/home")
          : this.$route.query.path;
        return path.replace(/\/app/g, "");
      }
      return "";
    }
  },
  watch: {
    isAuthenticated: {
      async handler() {
        await this.$nextTick();
        if (this.isAuthenticated) {
          this.redirectOnAuthenticated();
        }
      },
      immediate: true
    }
  },
  methods: {
    redirectOnAuthenticated: debounce(async function onAuth() {
      await this.$nextTick();

      if (this.isAuthenticated) {
        const path = this.deepLink || "/audioitems";
        const query = { ...this.$route.query };
        delete query.ticket;
        delete query.path;

        await this.$nextTick();
        this.$router.replace({
          path,
          query
        });
      }
    })
  }
});
</script>
